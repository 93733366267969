import React, { Component } from "react";
import smoothScrollTo, { smoothScrollToTop } from "utils/smoothScrollTo";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "components/Footer";
import FullWidthCallouts from "slices/FullWidthCallouts";
import Helmet from "react-helmet";
import Hero from "slices/Hero";
import ReadingList from "slices/ReadingList";
import ReadingListFilter from "components/ReadingListFilter";
import Route from "route-parser";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { graphql } from "gatsby";
import { navigate } from "gatsby";
import posed from "react-pose";
import { sine } from "utils/easing";
import styles from "./ReccoReading.module.scss";
import { sliceTypeMatches } from "utils/slices";

// Slices

// graphql

const ScrollToTopButton = posed.button({
  on: {
    x: 0,
    transition: {
      type: `tween`,
      ease: sine.out,
      duration: 300,
    },
  },
  off: {
    x: `101%`,
    transition: {
      type: `tween`,
      ease: sine.out,
      duration: 200,
    },
  },
});

class ReccoReadingPage extends Component {
  state = { showScrollToTop: false };

  static getDerivedStateFromProps(props, state) {
    let routeParser = new Route(`/recommended-reading/:showing(/)`);
    let { showing } = routeParser.match(props.location.pathname);
    return {
      showing: showing || state.lists[0].uid,
    };
  }

  static getPageMeta(globals, page, location) {
    let title = page.seo_title || page.title;
    let description = page.seo_description || globals.seo_description;
    let img_alt = page.seo_image_alt || globals.seo_image_alt || page.title;
    let fb_image = page.seo_image
      ? page.seo_image.facebook
      : globals.seo_image.facebook;

    let twitter_image = page.seo_image
      ? page.seo_image.twitter
      : globals.seo_image.twitter;

    let pageTitle = `${title.text} | Writers' Trust of Canada`;
    let meta = [
      { property: `og:site_name`, content: pageTitle },
      { name: `title`, content: title.text },
      { property: `og:title`, content: title.text },
      { name: `description`, content: description.text },
      { property: `og:description`, content: description.text },
      { property: `og:image`, content: fb_image.url },
      {
        property: `og:image:width`,
        content: fb_image.dimensions ? fb_image.dimensions.width : null,
      },
      {
        property: `og:image:height`,
        content: fb_image.dimensions ? fb_image.dimensions.height : null,
      },
      { property: `og:image:alt`, content: img_alt.text },
      {
        property: `og:url`,
        content: `https://www.writerstrust.com${location.pathname}`,
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:image`,
        content: twitter_image.url,
      },
    ];
    return meta.filter((m) => m.content);
  }

  constructor(props) {
    super(props);
    this.lists = props.data.readingLists.edges.map((edge) => edge.node);
    this.state = { lists: this.lists };
  }

  componentDidMount() {
    setTimeout(() => {
      // window.scrollTo(0, 0);
      window.addEventListener("scroll", this.onScroll);
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    if (window.scrollY > 1000) {
      if (!this.state.showScrollToTop) {
        this.setState({ showScrollToTop: true });
      }
    } else {
      if (this.state.showScrollToTop) {
        this.setState({ showScrollToTop: false });
      }
    }
  };

  scrollToTop = () => {
    smoothScrollTo(window, 0);
  };

  onFilterChange = ({ value }) => {
    let loc = `/${value}/`;
    navigate(loc, { state: { internal: true } });
  };

  renderSlice = (slice, nextSlice) => {
    if (!slice) {
      return null;
    }

    let result = null;
    let Cmp = null;
    let extras = {};
    const slice_type = slice.slice_type;
    switch (true) {
      case sliceTypeMatches(slice_type, "hero"):
        extras.bottomTheme =
          nextSlice && nextSlice.primary && nextSlice.primary.theme
            ? nextSlice.primary.theme
            : null;
        slice.primary.show_side_panel = true;
        extras.bottomCompressed = true;
        extras.bottomRenderChildren = (
          <ReadingListFilter
            onChange={this.onFilterChange}
            items={this.lists}
            showing={this.state.showing}
          />
        );
        Cmp = Hero;
        break;
      case sliceTypeMatches(slice_type, "full_width_callouts"):
        Cmp = FullWidthCallouts;
        break;
      case sliceTypeMatches(slice_type, "full_width_callout_list"):
        let items = slice.items;
        if (items.length && !items[0].link) {
          items = slice.items
            .map((item) => {
              return item && item.callout && item.callout.document
                ? item.callout.document[0].data
                : null;
            })
            .filter(Boolean);
        }
        slice.items = items;
        slice.primary = { ...slice.primary, theme: "grey" };
        Cmp = FullWidthCallouts;
        break;
      default:
        return null;
    }

    if (!Cmp && !result) {
      return null;
    }
    if (!Cmp && result) {
      return result;
    }
    return <Cmp {...slice} key={slice.id} {...extras} />;
  };

  shouldComponentUpdate(props) {
    if (props.location.pathname.indexOf(props.data.page.slug) === -1) {
      return false;
    }
    return true;
  }

  scrollToTop = () => {
    smoothScrollToTop();
  };

  render() {
    const { page } = this.props.data;
    const { globals, footerData } = this.props.pageContext;
    const { uid } = page;
    const { title, body } = page.data;
    const { showScrollToTop } = this.state;
    const showingList = this.lists.filter(
      (item) => item.uid === this.state.showing
    )[0];
    return (
      <React.Fragment>
        <Helmet
          bodyAttributes={{
            class:
              uid === "awards" || uid === "events" || uid === "programs"
                ? "landing-page"
                : "page",
          }}
          title={`${title.text} | Writers' Trust of Canada`}
          meta={ReccoReadingPage.getPageMeta(
            globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        {body[0] && this.renderSlice(body[0])}
        {showingList && (
          <ReadingList
            id={`readinglist-${showingList.uid}`}
            list={showingList}
          />
        )}
        {body[1] && this.renderSlice(body[1])}
        <Footer data={footerData} theme={page.data.footer_theme} />
        <ScrollToTopButton
          ref={(r) => (this.stb = r)}
          className={styles.scrollToTopButton}
          onClick={this.scrollToTop}
          pose={showScrollToTop ? `on` : `off`}
        >
          <span>Back to top</span>
          <i>
            <FontAwesomeIcon icon={faArrowUp} />
          </i>
        </ScrollToTopButton>
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query ($uid: String) {
    readingLists: allPrismicReadingList(
      filter: { data: { active: { eq: "yes" } } }
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          slug
          data {
            title {
              text
              html
            }
            list_label
            body {
              ... on PrismicReadingListBodyContentBlock {
                id
                slice_type
                primary {
                  columns
                  heading {
                    html
                  }
                }
                items {
                  content {
                    html
                  }
                }
              }
              ... on PrismicReadingListBodySelection {
                id
                slice_type
                primary {
                  author_name {
                    text
                  }
                  author_credentials {
                    text
                    html
                  }
                  hide_description_heading
                  description_heading {
                    text
                  }
                  description {
                    text
                    html
                  }
                }
                items {
                  book_title {
                    text
                    html
                  }
                  cover_image {
                    url
                    dimensions {
                      width
                      height
                    }
                  }
                  author {
                    text
                    html
                  }
                  publisher
                  short_story
                }
              }
            }
          }
        }
      }
    }
    page: prismicLandingPage(uid: { eq: $uid }) {
      uid
      slug
      data {
        seo_title {
          text
        }
        seo_description {
          text
        }
        seo_image {
          twitter {
            url
            dimensions {
              width
              height
            }
          }
          facebook {
            url
            dimensions {
              width
              height
            }
          }
        }
        title {
          text
        }
        footer_theme
        body {
          ... on PrismicLandingPageBodyHero {
            id
            slice_type
            primary {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              category {
                text
              }
              heading {
                text
              }
              hero_paragraph {
                html
              }
              button_label {
                text
              }
              button_link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              bottom_callout_heading {
                text
              }
              bottom_callout_large_heading {
                text
              }
              bottom_callout_paragraph {
                html
                text
              }
              bottom_callout_button_label {
                text
              }
              bottom_callout_button_link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              show_side_panel
              theme
              enable_footer
              enable_floating_side_panel
              show_footer_item_borders
              footer_heading {
                text
              }
            }
            items {
              item_size
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              heading {
                text
                html
              }
              anchor_id
              link {
                url
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
            }
          }
          ... on PrismicLandingPageBodyFullWidthCallouts {
            id
            slice_type
            primary {
              theme
            }
            items {
              link {
                raw {
                  link_type
                  target
                  slug
                  uid
                  id
                }
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              category_heading {
                text
              }
              heading {
                text
              }
              label {
                text
              }
            }
          }
          ... on PrismicLandingPageBodyFullWidthCalloutList {
            id
            slice_type
            primary {
              theme
            }
            items {
              callout {
                document {
                  data {
                    link {
                      raw {
                        link_type
                        slug
                        uid
                        id
                      }
                    }
                    image {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                    category_heading {
                      text
                    }
                    heading {
                      text
                    }
                    label {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ReccoReadingPage;
