import React, { PureComponent } from 'react';
import Dropdown from 'components/Dropdown';
import Columns from 'react-bulma-components/lib/components/columns';
import styles from './ReadingListFilter.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

class ReadingListFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ddShowingItems: props.items.map(item => {
        return {
          name:
            item.data.list_label && item.data.list_label !== ''
              ? item.data.list_label
              : item.data.title.text,
          value: item.slug,
          uid: item.uid
        };
      }) || [{ name: 'test', value: 'test' }]
    };
  }

  render() {
    return (
      <Columns mobile multiline className={cx({ filter: true })}>
        <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
          <Columns mobile multiline>
            <Columns.Column
              mobile={{ size: 11 }}
              tablet={{ size: 6 }}
              desktop={{ size: 5 }}
              widescreen={{ size: 3 }}
            >
              <Dropdown
                selectedItem={
                  this.state.ddShowingItems.filter(
                    item => item.uid === this.props.showing
                  )[0]
                }
                items={this.state.ddShowingItems}
                onChange={this.props.onChange}
                className={styles.showingFilter}
              />
            </Columns.Column>
          </Columns>
        </Columns.Column>
      </Columns>
    );
  }
}

export default ReadingListFilter;
