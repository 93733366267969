import React, { PureComponent } from "react";
import { renderHtml, renderText } from "utils/renderHelpers";

import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import ContentBlock from "slices/ContentBlock";
import ContentSection from "slices/ContentSection";
import HTMLContent from "slices/HTMLContent";
import Section from "react-bulma-components/lib/components/section";
import Waypoint from "react-waypoint";
import classNames from "classnames/bind";
import { renderFluidImage } from "utils/imageHelpers";
import styles from "./ReadingList.module.scss";
import { sliceTypeMatches } from "utils/slices";

const cx = classNames.bind(styles);

class BooksCarousel extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (`${props.id}-carousel` !== state.id) {
      return { currentIndex: 0, id: `${props.id}-carousel` };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.useIdleInterval = false;
    this.state = { currentIndex: 0, id: `${props.id}-carousel` };
  }

  nextSlide = () => {
    let currentIndex = this.state.currentIndex;
    currentIndex++;
    if (currentIndex >= this.props.items.length) {
      currentIndex = 0;
    }
    this.setState({ currentIndex });
  };

  prevSlide = () => {
    let currentIndex = this.state.currentIndex;
    currentIndex--;
    if (currentIndex < 0) {
      currentIndex = this.props.items.length - 1;
    }
    this.setState({ currentIndex });
  };

  onEnter = () => {
    if (this.useIdleInterval) {
      this.interval = setInterval(() => {
        this.nextSlide();
      }, 3000);
    }
  };

  onLeave = () => {
    clearInterval(this.interval);
  };

  render() {
    const { currentIndex } = this.state;
    const { items } = this.props;
    if (!items[currentIndex] || !items[currentIndex]?.cover_image) {
      return null;
    }
    return (
      <Waypoint
        key={`${this.state.id}-waypoint`}
        onLeave={this.onLeave}
        onEnter={this.onEnter}
      >
        <div id={this.state.id}>
          <Columns>
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
              <figure>
                {renderFluidImage(items[currentIndex].cover_image)}
              </figure>
              {items.length > 1 && (
                <fieldset
                  className={styles.controls}
                  aria-label="carousel buttons"
                  aria-controls={this.state.id}
                >
                  <button
                    aria-label="previous"
                    type="button"
                    onClick={() => {
                      clearInterval(this.interval);
                      this.prevSlide();
                    }}
                    className={cx({
                      carouselButton: true,
                      disabled: currentIndex === 0,
                    })}
                  >
                    <i className="icon" />
                    Previous
                  </button>
                  <button
                    aria-label="next"
                    type="button"
                    onClick={() => {
                      clearInterval(this.interval);
                      this.nextSlide();
                    }}
                    className={cx({
                      carouselButton: true,
                      disabled: this.state.currentIndex === items.length - 1,
                    })}
                  >
                    <i className="icon" />
                    Next
                  </button>
                </fieldset>
              )}
            </Columns.Column>
            {/* <Columns.Column className={styles.listItem}>
              {items.map((item, idx) => {
                return (
                  <button
                    key={`${this.state.id}-carousellink-${idx}`}
                    className={cx({ current: this.state.currentIndex === idx })}
                    onClick={() => {
                      this.setState({ currentIndex: idx });
                    }}
                  >
                    {renderText(
                      item.book_title,
                      'span',
                      styles.carouselBookTitle,
                      {},
                      null,
                      null,
                      null,
                      null,
                      null,
                      true,
                      item.short_story === 'true'
                    )}
                    {item.publisher && (
                      <span className={styles.publisher}>{item.publisher}</span>
                    )}
                  </button>
                );
              })}
            </Columns.Column> */}
          </Columns>
        </div>
      </Waypoint>
    );
  }
}

export default ({ list, id }) => {
  const { data } = list;
  const { title, body } = data;
  return (
    <Section className={`white ${cx({ section: true, white: true })}`}>
      <Container>
        <Columns mobile multiline>
          {title && title.text && (
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
              {renderText(title, `h2`)}
            </Columns.Column>
          )}
          {body.map((item, idx) => {
            let { primary, items, slice_type } = item;
            console.log({ slice_type });
            if (sliceTypeMatches(slice_type, "content_block")) {
              return (
                <ContentBlock
                  {...item}
                  contentStyle={{ margin: 0, paddingBottom: `4rem` }}
                />
              );
            } else {
              const {
                author_name,
                author_credentials,
                description,
                hide_description_heading,
                description_heading,
              } = primary;
              // items = [items[0]];
              return (
                <Columns.Column
                  className={styles.list}
                  key={`readinglist-${idx}`}
                  mobile={{ size: 12 }}
                  tablet={{ size: 12 }}
                >
                  <Columns mobile multiline>
                    <Columns.Column
                      mobile={{ size: 6 }}
                      tablet={{ size: 3 }}
                      dekstop={{ size: 2 }}
                    >
                      <BooksCarousel key={id} id={id} items={items} />
                    </Columns.Column>
                    <Columns.Column
                      mobile={{ size: 12 }}
                      tablet={{ size: 9 }}
                      desktop={{ size: 8 }}
                    >
                      <article className={styles.listDescription}>
                        {renderText(author_name, `h4`, styles.authorName)}
                        {renderHtml(
                          author_credentials,
                          `h5`,
                          styles.authorCreds
                        )}
                        {hide_description_heading !== true && (
                          <h4 className={styles.descriptionTitle}>
                            {description_heading && description_heading.text
                              ? description_heading.text
                              : `Recommendations`}
                          </h4>
                        )}
                        <HTMLContent columns={1} html={description} />
                      </article>
                    </Columns.Column>
                  </Columns>
                </Columns.Column>
              );
            }
          })}
        </Columns>
      </Container>
    </Section>
  );
};
